import { FaceLandmark } from "@zappar/zappar";
import { mat4 } from "gl-matrix";
import { THREE } from "../three";
import { CameraMirrorMode } from "../camera";
/**
 * A THREE.Group which attaches content to a known point (landmark) on a face as it moves around in the camera view.
 * Landmarks will remain accurate, even as the user's expression changes.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/face-tracking/
 */
export class FaceLandmarkGroup extends THREE.Group {
    /**
     * Constructs a new FaceLandmarkGroup.
     * @param camera - A ZapparThree.Camera.
     * @param faceTracker - The face tracker which will be used.
     * @param landmark - The landmark to which the group will be anchored.
     */
    constructor(camera, faceTracker, landmark) {
        super();
        this.camera = camera;
        this.faceTracker = faceTracker;
        this.pose = mat4.create();
        this.landmark = new FaceLandmark(landmark);
        // We'll be updating this Group's matrix ourselves from the Zappar library
        this.matrixAutoUpdate = false;
    }
    updateMatrixWorld(force) {
        if (!this.currentAnchor || !this.faceTracker.visible.has(this.currentAnchor)) {
            // No current anchor, or current anchor isn't visible
            this.currentAnchor = this.faceTracker.visible.values().next().value;
        }
        if (this.currentAnchor) {
            this.landmark.updateFromFaceAnchor(this.currentAnchor, this.camera.currentMirrorMode === CameraMirrorMode.Poses);
            mat4.multiply(this.pose, this.currentAnchor.pose(this.camera.rawPose, this.camera.currentMirrorMode === CameraMirrorMode.Poses), this.landmark.pose);
            this.matrix.fromArray(this.pose);
            this.matrix.decompose(this.position, this.quaternion, this.scale);
        }
        super.updateMatrixWorld(force);
    }
    /**
     * Destroys the face landmark.
     */
    dispose() {
        this.landmark.destroy();
    }
}
