import "./index.scss";
import * as THREE from "three";
import Alpine from "alpinejs";
import { bottle, prevBottle, nextBottle, activeBottle, startTracker } from "./ar";
window.Alpine = Alpine;

Alpine.data("ar", () => ({
	isStart: false,
	bottle: bottle,
	activeBottle: activeBottle,
	prev() {
		this.activeBottle = prevBottle();
	},
	next() {
		this.activeBottle = nextBottle();
	},
	startTracker() {
		startTracker();
		this.isStart = true;
	},
}));

Alpine.store("main", {
	progress: 0,
});

export const mainStore = Alpine.store("main");
THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
	mainStore.progress = (itemsLoaded / itemsTotal) * 100;
};

// nav
type ActiveNav = "start" | "game";
interface NavStore {
	isActive: ActiveNav;
	list: string[];
}

Alpine.store("nav", {
	isActive: "start",
	list: ["start", "game"],
	changeNav(nav: ActiveNav): void {
		this.isActive = nav;
	},
} as NavStore);

Alpine.start();
