import find from "lodash/find";

import * as THREE from "three";
import * as ZapparThree from "@zappar/zappar-threejs";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

const model = new URL("../assets/symphony-final-3-lowpoly.glb", import.meta.url).href,
	hdrUrl = new URL("../assets/poly_haven_studio_1k.hdr", import.meta.url).href,
	texturesURL = <const>{
		chuvstvennyy_pion: new URL("../assets/chuvstvennyy_pion.png", import.meta.url).href,
		izumrudnaya_rosa: new URL("../assets/izumrudnaya_rosa.png", import.meta.url).href,
		vishnovaya_sangriya: new URL("../assets/vishnovaya_sangriya.png", import.meta.url).href,
		charuyushchaya_magnoliya: new URL("../assets/charuyushchaya_magnoliya.png", import.meta.url).href,
		zhemchuzhnaya_laguna: new URL("../assets/zhemchuzhnaya_laguna.png", import.meta.url).href,
		khrustalnaya_svezhest: new URL("../assets/khrustalnaya_svezhest.png", import.meta.url).href,
	};

const bottle: {
	texture: THREE.Texture;
	title: string;
	text: string;
}[] = [
	{
		texture: new THREE.TextureLoader().load(texturesURL.khrustalnaya_svezhest),
		title: "Хрустальная Свежесть",
		text: `Symphony Premium Хрустальная Свежесть — кристально чистый аромат горного воздуха, насыщенный энергией озона, наполнит ваше пространство утонченной свежестью.<br/>
		<br/>
		Верхние Ноты: Лемонграсс, Пудра, Роза <br/>
		Средние Ноты: Озон,<br/>
		Белые Цветы<br/>
		Базовые Ноты: Мускус,<br/>
		Свежая Трава`,
	},
	{
		texture: new THREE.TextureLoader().load(texturesURL.izumrudnaya_rosa),
		title: "Изумрудная Роса",
		text: `Symphony Рremium Изумрудная Роса — капли росы играют в лучах утреннего солнца на листьях дерева бергамота и переливаются аккордами экзотического цитруса и влажного ветивера, вдохновляя на свежие идеи.<br/>
		<br/>
		Верхние Ноты: Бергамот, Пудра<br/>
		Средние Ноты: Зеленые Листья<br/>
		Базовые Ноты: Ветивер, Мох`,
	},
	{
		texture: new THREE.TextureLoader().load(texturesURL.charuyushchaya_magnoliya),
		title: "Чарующая Магнолия",
		text: `Symphony Рremium Чарующая Магнолия — аромат пышного южного сада, манящий роскошью аккордов магнолии и жасмина, помогает расцветать, раскрывая свой творческий потенциал.<br/>
		<br/>
		Верхние Ноты: Магнолия<br/>
		Средние Ноты: Жасмин, Бергамот<br/>
		Базовые Ноты: Мускус, Кедр
		`,
	},
	{
		texture: new THREE.TextureLoader().load(texturesURL.zhemchuzhnaya_laguna),
		title: "Жемчужная Лагуна",
		text: `Symphony Рremium Жемчужная Лагуна — свежее звучание океанского бриза и морской соли с аккордом приморской сосны создает чувственную атмосферу ночной прогулки на побережье.<br/>
		<br/>
		Верхние Ноты: Морской Бриз, Лайм<br/>
		Средние Ноты: Водоросли, Эвкалипт<br/>
		Базовые Ноты: Приморская Сосна`,
	},
	{
		texture: new THREE.TextureLoader().load(texturesURL.vishnovaya_sangriya),
		title: "Вишневая Сангрия",
		text: `Symphony Рremium Вишневая Сангрия — волнующий аромат сочной вишни с нотой терпкого миндаля, томящейся в бокале сангрии, навевает романтическое настроение.<br/>
		<br/>
		Верхние Ноты: Вишня, Пион<br/>
		Средние Ноты: Миндаль, Роза<br/>
		Базовые Ноты: Амбра`,
	},
	{
		texture: new THREE.TextureLoader().load(texturesURL.chuvstvennyy_pion),
		title: "Пряный Кедр",
		text: `Symphony Рremium Пряный Кедр и Роза — роскошная древесно-пряная композиция создана для дорогого звучания вашего интерьера и настроя на спокойствие и уверенность в себе.<br/>
		<br/>
		Верхние Ноты: Роза, Фиалка<br/>
		Средние Ноты: Бергамот, Розовый Перец<br/>
		Базовые Ноты: Кедр, Кардамон`,
	},
];

let activeBottle: number = 0,
	flowers: THREE.MeshBasicMaterial[] = [],
	startTracker: () => void;
const nextBottle = () => {
		++activeBottle;
		if (activeBottle >= bottle.length - 1) activeBottle = 0;
		let actualTexture = bottle[activeBottle].texture;

		flowers.forEach((flower) => {
			flower.map = actualTexture;
		});

		return activeBottle;
	},
	prevBottle = () => {
		--activeBottle;
		if (activeBottle < 0) activeBottle = bottle.length - 1;
		let actualTexture = bottle[activeBottle].texture;

		flowers.forEach((flower) => {
			flower.map = actualTexture;
		});

		return activeBottle;
	};

const target = document.querySelector("#ar");
if (target) {
	if (ZapparThree.browserIncompatible()) {
		ZapparThree.browserIncompatibleUI();
		throw new Error("Unsupported browser");
	}
	const renderer = new THREE.WebGLRenderer({ antialias: true }),
		scene = new THREE.Scene(),
		sceneMask = new THREE.Scene();

	target.appendChild(renderer.domElement);
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.autoClear = false;

	window.addEventListener("resize", () => {
		renderer.setSize(window.innerWidth, window.innerHeight);
	});
	const camera = new ZapparThree.Camera();

	let permissionTextIsAdd = false;
	const zapparPermissionText = (text: { title: string; text: string; btn: string; btnId: string }): void => {
		const titleEl = document.querySelector(".zappar-title");
		if (titleEl) {
			titleEl.innerHTML = text.title;
			permissionTextIsAdd = true;
		}
		const textEl = document.querySelector(".zappar-text");
		if (textEl) {
			textEl.innerHTML = text.text;
			permissionTextIsAdd = true;
		}
		const btnEl = document.querySelector(text.btnId);
		if (btnEl) {
			btnEl.innerHTML = text.btn;
			btnEl.classList.add("btn");
			permissionTextIsAdd = true;
		}
	};

	const startText = {
		title: "Доступ к камере",
		text: "Для работы AR требуется доступ к камере",
		btn: "Дать доступ",
		btnId: "#zappar-permission-request-button",
	};

	const startTextInterval = setInterval(() => {
		zapparPermissionText(startText);
		if (permissionTextIsAdd) clearInterval(startTextInterval);
	}, 100);

	ZapparThree.permissionRequest().then((granted) => {
		setTimeout(() => {
			zapparPermissionText(startText);
		}, 0);
	});

	ZapparThree.permissionRequestUI().then((granted) => {
		console.log(222);

		if (granted) {
			camera.start();
		} else {
			ZapparThree.permissionDeniedUI();
			setTimeout(() => {
				zapparPermissionText({
					title: "Перезагрузите страницу",
					text: "Необходимо разрешение на доступ к камере и датчикам движения вашего устройства. Затем перезагрузить страницу.",
					btn: "Перезагрузить",
					btnId: "#zappar-permission-reload-button",
				});
			}, 0);
		}
	});

	ZapparThree.glContextSet(renderer.getContext());
	scene.background = camera.backgroundTexture;
	const instantTracker = new ZapparThree.InstantWorldTracker(),
		imageTrackerGroup = new ZapparThree.InstantWorldAnchorGroup(camera, instantTracker),
		imageTrackerGroupMask = new ZapparThree.InstantWorldAnchorGroup(camera, instantTracker);

	scene.add(imageTrackerGroup);
	sceneMask.add(imageTrackerGroupMask);

	let action: THREE.AnimationAction[] = [],
		mixer: THREE.AnimationMixer;

	const scaleScene = 0.52,
		positionScene = {
			x: 0.03,
			y: -0.5,
			z: -0.2,
		};

	const gltfLoader = new GLTFLoader();
	let gScene: THREE.Group;
	gltfLoader.load(
		model,
		(gltf) => {
			gScene = gltf.scene;

			let flower = <THREE.Mesh>find(gScene.children, ["name", "Flower_Rose_Bungaria_sk002"]);
			flower.material.map = bottle[activeBottle].texture;
			flowers.push(flower.material);

			gScene.scale.set(scaleScene, scaleScene, scaleScene);
			gScene.rotation.y = THREE.MathUtils.degToRad(110);
			gScene.position.set(positionScene.x, positionScene.y, positionScene.z);

			mixer = new THREE.AnimationMixer(gltf.scene);
			for (const animation of gltf.animations) {
				const anim = mixer.clipAction(animation);
				anim.clampWhenFinished = false;
				anim.loop = THREE.LoopRepeat;
				anim.timeScale = 1;
				anim.play();
				action.push(anim);
			}
			imageTrackerGroup.add(gScene);
			gScene.visible = false;
		},
		undefined,
		() => {
			console.log("An error ocurred loading the GLTF model");
		}
	);

	const cylinderMask = new THREE.Mesh(
		new THREE.CylinderGeometry(1.1, 1.1, 8.5, 32),
		new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.3 })
	);
	cylinderMask.position.set(-0.07, -0.6, -0.19);
	cylinderMask.scale.set(scaleScene, scaleScene, scaleScene);
	const cylinderAim = cylinderMask.clone();

	imageTrackerGroupMask.add(cylinderMask);
	imageTrackerGroup.add(cylinderAim);

	// hdr
	new RGBELoader().load(hdrUrl, (texture) => {
		texture.mapping = THREE.EquirectangularReflectionMapping;
		scene.environment = texture;
	});

	// scene.visible = false;

	const resetPosition = {
		x: 0,
		y: -0.5,
		z: -7,
	};

	let hasPlaced = false;
	startTracker = () => {
		cylinderAim.visible = false;
		gScene.visible = true;

		// scene.visible = true;
		hasPlaced = true;
		imageTrackerGroup.setAnchorPoseFromCameraOffset(resetPosition.x, resetPosition.y, resetPosition.z);
		imageTrackerGroupMask.setAnchorPoseFromCameraOffset(resetPosition.x, resetPosition.y, resetPosition.z);
	};

	const clock = new THREE.Clock();
	const raycaster = new THREE.Raycaster();
	function render(): void {
		if (!hasPlaced) {
			imageTrackerGroup.setAnchorPoseFromCameraOffset(resetPosition.x, resetPosition.y, resetPosition.z);
			imageTrackerGroupMask.setAnchorPoseFromCameraOffset(resetPosition.x, resetPosition.y, resetPosition.z);
		}

		camera.updateFrame(renderer);
		raycaster.setFromCamera(new THREE.Vector2(0, 0), camera);
		mixer?.update(clock.getDelta());

		renderer.render(sceneMask, camera);
		renderer.render(scene, camera);
		requestAnimationFrame(render);
	}
	render();
}

export { nextBottle, prevBottle, startTracker, activeBottle, bottle };
