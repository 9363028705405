import { z } from "./zappar";
export { log_level_t as LogLevel } from "@zappar/zappar-cv";
/**
 * @returns The granularity of logging emitted by the library.
*/
export function logLevel() {
    return z().log_level();
}
/**
 * Sets the granularity of logging emitted by the library.
*/
export function setLogLevel(l) {
    z().log_level_set(l);
}
